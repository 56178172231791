/* DomainVerification Component Styles */

/* Container for the domain verification section */
.domain-verification-container {
  max-width: 1200px;
  min-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.domain-verification-title {
  text-align: center;
  color: #333;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.domain-verification-p {
  text-align: center;
  color: #333;
}

/* Form styling */
.domain-verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 2rem;
}

.domain-verification-input {
  padding: 0.75rem;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

.domain-verification-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #F87339;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.domain-verification-button:hover {
  background-color: #e06632;
}

/* Error message styling */
.domain-verification-error {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

/* DNS Records and Domains Status section styling */
.domain-verification-records {
  margin-top: 2rem;
}

.domain-verification-subtitle {
  color: #333;
  font-weight: 500;
  margin-bottom: 1rem;
}

.domain-verification-pre {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: auto;
}

/* Domains list styling */
.domain-verification-list {
  list-style-type: none;
  padding: 0;
}

.domain-verification-list-item {
  background-color: #fff;
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

/* Domain name styling */
.domain-verification-domain-name {
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 10px;
  color: #333;
}

/* Domain status styling */
.domain-verification-domain-status {
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  color: #fff;
}

.domain-verification-authenticated {
  background-color: #28a745;
}

.domain-verification-not-authenticated {
  background-color: #dc3545;
}

/* Table styling */
.domain-verification-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.domain-verification-th,
.domain-verification-td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.domain-verification-th {
  background-color: #f2f2f2;
}

/* Specific styling for columns to allow/prevent wrapping */
.domain-verification-td-wrap {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  max-width: 600px; /* Adjust this value as needed */
}

.domain-verification-td-no-wrap {
  white-space: nowrap;
}

.tutorial-link-container {
  text-align: center;
  margin-top: 2rem;
}

.tutorial-link {
  color: #F87339;
  text-decoration: none;
  font-weight: 600;
}

.tutorial-link:hover {
  text-decoration: underline;
}

.why-link {
  color: #F87339;
  text-decoration: none;
  font-weight: 600;
  margin-left: 5px;
}

.why-link:hover {
  text-decoration: underline;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #F87339;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

