@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
/* App-wide styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('./assets/Desktop\ -\ 9.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #333;
}

.App{
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff; /* Light background for the header */
  width: 100%;
  box-sizing: border-box;
}

.header a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  margin-left: 20px;
}

.header a:hover {
  color: #F87339; /* Accent color on hover */
}

.header div {
  display: flex;
  align-items: center;
}

.link {
  padding: 10px;
  text-decoration: none;
  color: black; /* Default link color */
}

.active-link {
  color: orange !important; /* Active link color */
}

.welcome-message {
  margin-right: 20px;
  font-size: 16px;
  color: #555;
}

.sign-out-button {
  background-color: #F87339;
  margin-right: 20px;
  margin-top: 0px;
}

.sign-out-button:hover {
  background-color: #e06632; /* Darker shade for hover state */
}


.tool {
  max-width: 800px;
  min-width: 800px;
  font-family: 'Poppins', sans-serif;
  margin: 1rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #000000;
  font-weight: 600;
  font-size: 70px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 0;
}

h3{
  color: #000000;
  font-weight: 500;
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 100px;
  text-align: center;
}

/* Form and input styles */
form {
  margin-bottom: 2rem;
}

textarea {
  width: 100%;
  padding: 0rem;
  margin-top: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: 'Poppins', sans-serif;
  width: 95%;
}

button {
  background: #0e0e0e;
  color: #fff;
  border: 2px solid transparent;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button-link{
  background: #0e0e0e;
  color: #fff !important;
  border: 2px solid transparent;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button-google {
  background: #F87339 !important;
  color: #fff;
  border: 2px solid transparent;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/*Info Input*/
.info-input-form{
  display: flex;
  flex-direction: column;
}

input[type="checkbox"]{
  margin-bottom: 0;
  margin-top: 0;
  margin: 15px;
}

.select-all-checkbox, .email-select-checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px; /* Space between the checkbox and the label */
  cursor: pointer; /* Indicates clickable items */
  accent-color: #F87339; /* Customize the color of the checkbox */
}

.select-all-checkbox{
  margin: 0 !important;
}

.select-all-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Space below the select-all checkbox */
  padding: 10px;
  background-color: #f0f0f0; /* Light grey background for distinction */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}


/* Business Info Display styles */
.business-info-container {
  background: #f0f0f0;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.business-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.business-email {
  color: #555;
  margin-bottom: 0.5rem;
}

.business-name, .business-email {
  max-width: 200px; /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-info-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Space between elements */
}


.email-toggle-btn {
  min-width: 150px;
  color: #ffffff;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 7px;
  cursor: pointer;
  font-size: 0.875rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.email-toggle-btn:hover {
  background: #000000;
  color: #fff;
}

.email-content {
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  
}

/* UserGoalInputForm styles */
form {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 2rem;
}

form[type="goal"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

label {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #333;
}

textarea {
  min-width: 100%; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  padding: 10px; /* Adjust padding as needed */
  border: 2px solid #ddd;
  border-radius: 6px;
  resize: vertical; /* Allows users to resize textarea vertically */
  font-family: 'Poppins', sans-serif; /* Keep your font consistent */
  font-size: 1rem; /* Adjust font size as needed */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

textarea[type="goal"]{
  max-width: 100px !important; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  padding: 10px; /* Adjust padding as needed */
  border: 2px solid #ddd;
  border-radius: 6px;
  resize: vertical; /* Allows users to resize textarea vertically */
  font-family: 'Poppins', sans-serif; /* Keep your font consistent */
  font-size: 1rem; /* Adjust font size as needed */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

input{
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 7px; /* Adjust padding as needed */
  resize: vertical; /* Allows users to resize textarea vertically */
  font-family: 'Poppins', sans-serif; /* Keep your font consistent */
  font-size: 1rem; /* Adjust font size as needed */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  margin-bottom: 20px;
}


input:focus {
  outline: none;
  border-color: #0e0e0e;
}

textarea:focus {
  outline: none;
  border-color: #0e0e0e;
}



button[type="submit"] {
  font-family: 'Poppins', sans-serif;
  background-color: #F87339;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  min-width: 200px;
  max-height: 40px;
  max-width: 200px;
}

button[type="submit"]:hover {
  background-color: #e06632;
}

.send-email-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Additional styles for the sent message */
.sent-message {
  color: #F87339;
  font-weight: bold;
  font-size: 1rem;
}

.navigation {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}

.send-button-section{
  display: flex;
  justify-content: center;
}
.back-button {
  background: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  box-shadow: none;
  text-align: left;
  padding: 0;
  color: black;
}

.back-button img {
  width: 24px;
  height: 24px;
}

.upload-csv{
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
}

.upload-csv-button{
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  max-height: 20px;
  margin-top: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  cursor: not-allowed;
}

.upload-placeholder {
  position: relative;
  display: inline-block;
  cursor: not-allowed;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  margin-right: 0;
}

.upload-placeholder i {
  color: #ccc;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #F87339;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 250%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F87339 transparent transparent transparent;
}

.upload-placeholder:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%; 
  border-left-color: #F87339;
  animation: spin 1s ease infinite;
  margin: auto;
}

.footer {
  display: flex;
  align-items: center;
  background-color: transparent; /* Transparent background */
  color: #F87339; /* Text color */
  padding: 10px 40px; /* Adjust padding as needed */
  min-width: 100vw;
  box-sizing: border-box;
}

.footer-left {
  flex: 1;
}

.footer-right {
  display: flex;
  gap: 20px; /* Space between the links */
}

.footer a {
  color: #F87339; /* Link color */
  text-decoration: none; /* Remove underline */
}

.footer a:hover {
  color: #e06632; /* Slightly darker shade for hover state */
}


.credits-info {
  font-weight: 600;
  margin-right: 20px;
}

/* App.css */
/* Add these styles to the bottom of your App.css */

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.subscribe-button {
  background-color: #F87339;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin: 10px;
}

.subscribe-button:hover {
  background-color: #e06632;
}


.close-button {
  background-color: #cccccc !important; /* Gray background */
  color: #333 !important; /* Dark text */
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin: 10px;
}

.close-button:hover {
  background-color: #aaaaaa; /* Darker gray for hover state */
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.button-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  width: 24px;
  height: 24px;
  animation: spin 1s ease infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

