/* src/components/Onboarding.css */

.onboarding-container {
    text-align: center;
  }
  
  .schedule-text {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .waiting-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  