/* components/FAQ.css */
.faq-section {
    width: 800px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 8px;
    margin-top: 150px;
    padding-top: 100px;
    font-family: 'Poppins', sans-serif;
  }
  
  .faq-section h2 {
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .faq-question {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  
  .faq-icon {
    font-size: 1.5rem;
    color: #000;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding: 10px;
    font-size: 1rem;
    color: #333;
  }
  