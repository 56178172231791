.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Increased opacity */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .modal h2 {
    margin-top: 0;
    color: #fff !important;
  }
  
  .modal button {
    margin-top: 20px;
    background-color: #F87339;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modal button:hover {
    background-color: #e06632;
  }
  