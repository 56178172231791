.privacy-policy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-container h1 {
    color: #F87339; /* Use the orange color */
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .privacy-policy-container h2 {
    color: #333;
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .privacy-policy-container p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container li {
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .privacy-policy-container strong {
    color: #333;
  }
  