/* Campaigns Display styles */
.campaigns-container {
    margin: 1rem auto;
    max-width: 800px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.campaigns-table {
    width: 100%;
    border-collapse: collapse;
}

.campaigns-table th, .campaigns-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
}

.campaigns-table th {
    background-color: #f0f0f0;
    color: #333;
    font-weight: 600;
}

.campaigns-table tbody tr:hover {
    background-color: #f9f9f9;
}

.campaigns-table tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

.campaigns-table td {
    color: #666;
}

.campaign-response-rate {
    font-weight: bold;
    color: #0e0e0e;
}

.email-detail {
    cursor: pointer;
    background-color: #f0f0f0;
}

.email-summary {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.email-message-preview {
    font-style: italic;
    color: #555;
}
