/* VerificationTutorial.css */

.tutorial-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.tutorial-title {
  text-align: left;
  color: #333;
  font-size: 50px;
  margin-bottom: 2rem;
}

.tutorial-intro {
  color: #666;
  line-height: 1.6;
}

.tutorial-step {
  margin-bottom: 2rem;
}

.tutorial-step-title {
  color: #F87339;
  margin-bottom: 0.5rem;
}

.tutorial-step-text {
  margin: 0;
  color: #666;
}

.brevo-link {
  color: #F87339;
  text-decoration: none;
}

.brevo-link:hover {
  text-decoration: underline;
}