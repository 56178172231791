/* Subscription.css */
.subscription-container {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.modal{
    display: flex;
    flex-direction: column;
}

.plan-card {
    flex: 1;
    padding: 20px;
    margin-right: 20px;
    max-width: 270px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
}

/* Styling for the Pro plan with a dark background */
.plan-card.pro-plan {
    background: #1e1e1e;
    color: #ffffff !important; /* Ensuring text is readable on dark background */
}

/* Styling for the Ultimate plan with an orange background */
.plan-card.ultimate-plan {
    background: #F87339;
    color: #ffffff !important; /* Ensuring text is readable on bright background */
}

.plan-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #ffffff !important;
}

.plan-card ul {
    list-style: none;
    padding: 0;
}

.plan-card li {
    font-size: 15px;
    line-height: 2.5;
    font-weight: 500;
}

.plan-card .price {
    font-size: 26px;
    font-weight: bold;
    margin: 20px 0;
}

.plan-card button {
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: #fff; /* White button to contrast the card */
    color: #333; /* Dark text for visibility */
}

.cancel-button {
    border: none;
    max-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: #000000; /* Black button for contrast */
    color: #fff; /* White text for visibility */
    margin-bottom: 50px;
}

.plan-card button:hover, .cancel-button:hover {
    background-color: #ddd; /* Light grey on hover for all buttons */
}

.cancel-button:hover {
    background-color: #323232; /* Darker grey for cancel button on hover */
}
