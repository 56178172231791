/* SignPage.css */

.sign-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  overflow: hidden;
}

.sign-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: 150px;
  width: 100%;
  min-height: 500px;
  text-align: center;
}

.info-container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F87339;
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 2rem;
}

h2 {
  margin-bottom: 1rem;
  color: #333;
}

.error-message {
  color: #ff0000;
  margin-bottom: 1rem;
}

.sign-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.sign-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #F87339;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-form button:hover {
  background-color: #e06632;
}

.text-button {
  background: none !important;
  border: none !important;
  color: #F87339 !important;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  box-shadow: none !important;
  padding: 0;
  text-decoration: none;
}

.verification-message {
  text-align: center;
}

.image-placeholder img {
  width: 70%;
  height: auto;
  border-radius: 8px;
  margin-top: 130px;
}

.text{
  width: 300px !important;
}

.logo {
  width: 100px; /* Adjust the size as needed */
  margin-bottom: 1rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.limit-reached-message a{
  color: #F87339;
}



.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #F87339;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
